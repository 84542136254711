import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class VehicleRoll extends React.Component {
  renderSubGroup = (vehicles, groupName) => {
    const {location: {pathname}} = this.props.data;
    const reg = new RegExp("/en")
    const en = reg.test(pathname);
    return vehicles.edges
        .filter(({ node:{frontmatter }}) =>
            frontmatter.category.toLowerCase() === groupName)
        .map(({node:{frontmatter, id, fields}}) =>
            <div className={'column is-4'} key={id}>
              <Link to={en ? `/en${fields.slug}` : fields.slug}>
                <div className="vehicle-card card ">
                  <div className="card-image">
                    <figure className="image is-2by1 is-marginless is-paddingless">
                      {(frontmatter.images && frontmatter.images[0]) &&
                        <PreviewCompatibleImage imageInfo={frontmatter.thumbnail || frontmatter.images[0]} />
                      }
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="content">
                      {frontmatter.title}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
        )
  }

  renderCategories = () => {
    const {categories, vehicles, en} = this.props.data;
    return categories.edges.map(({node: {frontmatter:{title}, id: titleID}}) =>
        <React.Fragment key={titleID}>
          <div className="columns is-multiline is-vcentered has-padding-bottom has-side-padding">
            <div className={'column is-10 is-offset-1'}>
              <div className="columns is-multiline is-vcentered has-border-bottom has-padding-bottom is-between is-flex">
                <h3 className={'title is-dark-snd is-inline-block is-marginless'}>{`${en ? 'Our' : 'Unsere'} ${title}`}</h3>
                <button className="button is-rounded blueborder">
                  <a className={'is-blue'} href={'/preise'}>
                    {en ? "Compare Prices" : "Preise vergleichen"}</a> </button>
              </div>
            </div>
            </div>
          <div className="columns is-multiline" style={{marginBottom:48}}>
            <div className="column is-10 is-offset-1">
              <div className="columns is-multiline">
                {this.renderSubGroup(vehicles, title.toLowerCase())}
              </div>
            </div>
          </div>
        </React.Fragment>
    )
  }
  render() {
    // console.log(this.props.data)
    // const { data } = this.props
    // const { categories: {edges: vehicleCategories} } = data
    // const { edges } = data.vehicles
    // console.log(edges)
    // console.log(vehicleCategories)
    return this.renderCategories()
  }
}

VehicleRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}
const query = graphql`
    query VehicleRollQuery {
        vehicles: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "vehicle-page" } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        category
                        thumbnail {
                                childImageSharp {
                                    fluid(maxWidth: 526, quality: 92) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        teaser_en
                        detail_header
                        detail_header_en
                        details {
                            detail
                            detail_name_de
                            detail_value_de
                        }
                        komfort_header
                        komfort_header_en
                        komfort {
                            komfort_en
                        }
                        preise {
                            start
                            end
                            price
                            name
                        }
                        templateKey
                        date(formatString: "MMMM DD, YYYY")
                        images {
                            title
                            alt
                            image {
                                name
                                childImageSharp {
                                    fluid(maxWidth: 526, quality: 92) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        categories: allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "categories" } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    id
                    frontmatter {
                        title
                    }
                }
            }
        }
    }
`
export default (props) => (
    <StaticQuery
        query={query}
        render={(data, count) => <VehicleRoll data={{...data, ...props}} />}
    />
)
