import React, {lazy, useEffect} from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import car from '../img/car.png'
import globe from '../img/globe.png'
import Layout from '../components/Layout'
import VehicleRoll from "../components/VehicleRoll";
import {toast} from 'bulma-toast';
/*let toast;
if (typeof window !== `undefined` && window.document) {
  toast = require("bulma-toast");
}*/
export const IndexPageTemplate = ({
                                    youtube,
                                    title,
                                    heading,
                                    subheading,
                                    intro,
                                    location,
                                    advantages,
                                    discount = null,
                                    en
                                  }) => (
    <div>
      <div
          className="margin-top-0"
          style={{
            backgroundPosition: `top left`,
            backgroundColor: '#3f89a6',
            backgroundAttachment: `fixed`,
          }}
      >
        <div style={{
          display: 'flex',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          overflow: "hidden",
          flexDirection: 'column',
        }}>

          <div className={'animation'}>
            <div className="globe"><img src={globe} alt="globe" width="716" height="716"/></div>
            <div className="car"><img src={car} alt="car" width="100" height="100"/></div>
          </div>
          <section className="hero is-relative">
            {discount &&
            <div className="ribbon ribbon-top-right">
              <span>
                {discount.titel}
                <br/>
                {discount.zusatz &&
                <small>
                  {discount.zusatz}
                </small>
                }
            </span>
            </div>
            }

            <div className="hero-body">
              <div className="container">
                <div className="column is-offset-1"/>
                <div className="column is-11 is-offset-1">
                  <h1 className="title has-text-centered-mobile" style={{color: 'white'}}>
                    {title}
                  </h1>
                </div>
                <div className="column is-8 is-offset-1">
                  <h3 className="subtitle is-dark-snd has-text-centered-mobile">
                    {intro}
                  </h3>
                </div>
                <div className="column is-7 is-offset-1">
                  <p className="has-text is-size-6 has-text-centered-mobile" style={{color: 'white'}}>
                    {subheading}
                  </p>
                  <br/>
                  <br/>
                  <div className="columns is-flex is-centered is-hidden-tablet is-hidden-desktop is-hidden-widescreen">

                    <div className="button is-rounded bluebg" style={{borderColor: 'transparent'}}>
                      <a style={{color: 'white', fontWeight: 600, letterSpacing: '.1rem', fontSize: 11}}
                         className="is-uppercase is-raleway"
                         href={en ? "/en/vw-busse-und-wohnmobile-mieten" : "/vw-busse-und-wohnmobile-mieten"}>
                        {en ? 'Choose your campervan' : 'Reisemobil auswählen'}
                      </a>
                    </div>
                  </div>
                  <div className="columns is-hidden-mobile">
                    <div className="column">
                      <div className="button is-rounded bluebg" style={{borderColor: 'transparent'}}>
                        <a style={{color: 'white', fontWeight: 600, letterSpacing: '.1rem', fontSize: 11}}
                           className="is-uppercase is-raleway"
                           href={en ? "/en/vw-busse-und-wohnmobile-mieten" : "/vw-busse-und-wohnmobile-mieten"}>
                          {en ? 'Choose your campervan' : 'Reisemobil auswählen'}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-hidden-mobile is-hidden-tablet-only">
            <div className="container columns">
              <div className="column is-offset-1"/>
              <div className="column is-11" style={{paddingLeft: 20}}>
                <iframe src={youtube} frameBorder="0"
                        allowFullScreen="" name="fitvid0"/>
              </div>
            </div>
          </section>
        </div>
      </div>

      <section className="section columns" style={{paddingLeft: 50}}>
        <div className="column is-6 is-offset-1 is-hidden-desktop is-hidden-widescreen">
          <div className="subtitle is-dark-snd" style={{marginTop: 18}}>
            {en ? 'On the road with our Campers' : 'Unterwegs mit unseren fahrzeugen'}
          </div>
          <iframe src={youtube} frameBorder="0"
                  allowFullScreen="" name="fitvid0"/>
        </div>

        <div className="column is-5 is-12-mobile is-offset-1">
          <div className="subtitle is-dark-snd"
               style={{marginTop: 18}}>{en ? 'Our Advantages' : 'Unsere Vorteile'}</div>
          <ul className="has-text-grey-dark has-text-weight-light is-raleway" style={{marginBottom: 24}}>
            {advantages.map(({advantage, advantage_en}) =>
                <li key={advantage} style={{marginBottom:8}}>
                  - {en ? advantage_en : advantage}
                </li>
            )}
          </ul>
        </div>
      </section>

      <section className="section" style={{paddingLeft: 50}}>
        <VehicleRoll
            en={en}
            location={location}/>
      </section>

    </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.string,
}

const IndexPage = ({data, location}) => {
  const {frontmatter} = data.markdownRemark
  useEffect(() => {
    if(frontmatter.discount.description && typeof window !== 'undefined' && window.document) {
      toast({
        message: frontmatter.discount.description,
        type: 'is-info',
        position: 'bottom-center',
        closeOnClick: true,
        pauseOnHover: true,
        dismissible: true,
        duration:600000,
        opacity: 1,
      })
    }
  })
  return (
      <Layout location={location}>
        <IndexPageTemplate
            title={frontmatter.title}
            subheading={frontmatter.subheading}
            intro={frontmatter.intro}
            youtube={frontmatter.youtube}
            advantages={frontmatter.advantages}
            location={location}
            discount={frontmatter.discount}
            en={new RegExp("en").test(location.pathname)}
        />
      </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
    query IndexPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            frontmatter {
                title
                subheading
                intro
                discount {
                    titel
                    zusatz
                    description
                }
                youtube
                advantages {
                    advantage
                    advantage_en
                }
            }
        }
    }
`
